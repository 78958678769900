<script lang="ts" setup>
import { onMounted, onUpdated, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useEmployeesStore } from "@/stores/employeesStore";
import { useClientsStore } from "@/stores/clientsStore";
import { useHelperStore } from "@/stores/helperStore";
import Avatar from "@/pages/components/Avatar.vue";
import { useUiStore } from "@/stores/uiStore";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Button from "@/base-components/Button";
import Lucide from "@/base-components/Lucide";
import FormSlider from "@/pages/FormSlider.vue";
import FormEmployee from "@/pages/forms/FormEmployee.vue";
import TabEmployeeDetails from "@/pages/components/TabEmployeeDetails.vue";
import EmployeeInterface, {
    EmployeeScheduleInterface,
    EmployeeStatusTypeGroupInterface,
    EmployeeActiveDateInterface,
} from "@/pages/interfaces/employeesInterface";
import EmployeeShifts from "@/pages/components/employees/EmployeeShifts.vue";
import EmployeeSalaryHistory from "@/pages/components/employees/EmployeeSalaryHistory.vue";
import EmployeeTickets from "@/pages/components/employees/EmployeeTickets.vue";
import EmployeeAttendance from "@/pages/components/employees/EmployeeAttendance.vue";
import EmployeeNote from "@/pages/components/employees/EmployeeNote.vue";
import EmployeeCostHistory from "@/pages/components/employees/EmployeeCostHistory.vue";
import EmployeeStatusHistory from "@/pages/components/employees/EmployeeStatusHistory.vue";
import EmployeeNonrefundableHistory from "@/pages/components/employees/EmployeeNonrefundableHistory.vue";
import { Tab } from "@/base-components/Headless";
import EmployeeSchedule from "@/pages/components/employees/EmployeeSchedule.vue";
import EmployeeReFees from "@/pages/components/employees/EmployeeReFees.vue";
import EmployeeRecentFile from "@/pages/components/employees/EmployeeRecentFile.vue";
import EmployeePersonalInformationSkeleton from "@/pages/components/EmployeePersonalInformationSkeleton.vue";
import EmployeeClientAssignment from "@/pages/components/employees/EmployeeClientAssignment.vue";

const AuthStore = useAuthStore();
const route = useRoute();

let id: string | string[] = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

watch(
    () => route.params.id,
    async (newId) => {
        id = Array.isArray(newId) ? newId[0] : newId;

        if (!isNaN(+id)) {
            loading.value = true;
            await populateEmployeeDetails();
            loading.value = false;
        }
    }
);

interface EmployeeData {
    id: number;
}

const updateEmployee = async (data: EmployeeData) => {
    isEmployeeFormSliderOut.value = true;
    employeeId.value = data.id;
    employeeApiData.data = data;
};

const UiStore = useUiStore();

const formEmployeeStatus = (status: any) => {
    if (status) {
        populateEmployeeDetails();
    }
};

let employee = reactive<EmployeeInterface>({
    name: "",
    position: "",
    client: null,
    third_party_employee_id: "",
    timezone_id: 0,
    office_id: "",
    primary_work_location: "",
    secondary_work_location: "",
    start_date: "",
    orientation_date: "",
    shift_schedule: "",
    work_phone: "",
    work_email: "",
    client_email: "",
    personal_phone: "",
    personal_email: "",
    emergency_contact_name: "",
    emergency_contact_phone: "",
    emergency_contact_relationship: "",
    sss: "",
    pagibig: "",
    tin: "",
    phic: "",
    payment_method: "",
    bank_account_no: "",
    bank_type: "",
    bank_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    zip: "",
    id: 0,
    user_id: "",
    client_id: 0,
    regularization_date: "",
    notes: null,
    internal_staff_flag: "",
    is_salaried: false,
    "201_complete": false,
    basic_salary: "",
    status: "",
    sensitiveData: false,
    attachments: [],
    employee_teams: [],
    shift: {} as EmployeeScheduleInterface,
    shifts: [],
    employee_status_type_group: {} as EmployeeStatusTypeGroupInterface,
    first_employee_active_date: {} as EmployeeActiveDateInterface,
    latest_active_date: {} as EmployeeActiveDateInterface,
    latest_employee_active_date: {} as EmployeeActiveDateInterface,
});

const HelperStore = useHelperStore();

const uiData = reactive({
    clientId: route.query.id,
});

const ClientStore = useClientsStore();
const EmployeesStore = useEmployeesStore();
const loading = ref(false);
onMounted(async () => {
    loading.value = true;
    // await AuthStore.getLoggedInUserDetails();
    
    if (HelperStore.attachmentTypes.length === 0) {
        await HelperStore.fillAttachmentTypes();
    }

    if (HelperStore.timezones.length === 0) {
        await HelperStore.fillTimezones();
    }
    await populateEmployeeDetails();
    window.scrollTo(0, 0);
    loading.value = false;
});

const populateEmployeeDetails = async () => {
    if (!AuthStore.hasPermission("can view all employees")) return;
    await HelperStore.fillEmployeeStatusHistories(+id);
    await EmployeesStore.getEmployeeDetails(+id);

    const employeeData = EmployeesStore.employee;
    employee.office = employeeData.office;
    employee.office_id = employeeData.office_id;

    employee.name = employeeData.name;
    employee.position = employeeData.position;
    employee.client = employeeData.client;
    employee.client_id = employeeData.client_id;
    employee.third_party_employee_id = employeeData.third_party_employee_id;
    employee.basic_salary = employeeData.basic_salary;
    employee.regularization_date = employeeData.regularization_date
        ? employeeData.regularization_date
        : "";
    employee.secondary_work_location = employeeData.secondary_work_location;

    employee.secondary_work_location = employeeData.secondary_work_location;
    employee.primary_work_location = employeeData.primary_work_location;
    employee.employee_active_dates = employeeData.employee_active_dates;
    employee.id = employeeData.id;
    employee.notes = employeeData.notes;
    employee.employee_status_type_group = employeeData.employee_status_type_group;
    employee.latest_active_date = employeeData.latest_active_date;
    employee.latest_employee_active_date = employeeData.latest_employee_active_date;
    employee.first_employee_active_date = employeeData.first_employee_active_date;
    employee.user_id = employeeData.user_id;

    employee.shift_schedule = employeeData.shift_schedule;
    employee.work_phone = employeeData.work_phone;
    employee.work_email = employeeData.work_email;
    employee.sprout_id = employeeData.sprout_id;
    employee.client_email =
        employeeData.client_email !== null ? employeeData.client_email : "";
    employee.personal_phone = employeeData.personal_phone;
    employee.personal_email = employeeData.personal_email;
    employee.timezone_id = employeeData.timezone_id;
    employee.internal_staff_flag = employeeData.internal_staff_flag;
    employee["201_complete"] = employeeData["201_complete"];
    employee.is_salaried = employeeData.is_salaried;

    employee.payment_method = employeeData.payment_method
    employee.bank_account_no = employeeData.bank_account_no
    employee.bank_name = employeeData.bank_name
    employee.bank_type = employeeData.bank_type

    employee.emergency_contact_name = employeeData.emergency_contact_name;
    employee.emergency_contact_phone = employeeData.emergency_contact_phone;
    employee.emergency_contact_relationship =
        employeeData.emergency_contact_relationship;
    employee.sss = employeeData.sss;
    employee.pagibig = employeeData.pagibig;
    employee.tin = employeeData.tin;
    employee.phic = employeeData.phic;
    employee.address_line_1 = employeeData.address_line_1;
    employee.address_line_2 = employeeData.address_line_2;
    employee.city = employeeData.city;
    employee.zip = employeeData.zip;
    employee.start_date = employeeData.first_employee_active_date
        ? employeeData.first_employee_active_date.start_date
        : "";
    employee.orientation_date = employeeData.first_employee_active_date
        ? employeeData.first_employee_active_date?.orientation_date
        : "";

    employee.payment_method = employeeData.payment_method ?? "";

    employee.attachments = employeeData.attachments;
    employee.shift = employeeData.shift;
    employee.shifts = employeeData.shifts;
    employee.user = employeeData.user;
    employee.employee_current_cost_history =
        employeeData.employee_current_cost_history;
    employee.employee_current_nonrefundable_history =
        employeeData.employee_current_nonrefundable_history;
    employee.employee_current_re_fee = employeeData.employee_current_re_fee;

    console.log('herehere', employeeData.employee_active_dates)
};

onUpdated(() => {
    UiStore.setBreadcrumb("Employee Details > " + employee.name);
});

const employeeId = ref(0);

interface EmployeeApiData {
    data: any;
}

const employeeApiData = reactive<EmployeeApiData>({ data: [] });

const isEmployeeFormSliderOut = ref(false);

const closeSlider = () => {
    employeeId.value = 0;
    isEmployeeFormSliderOut.value = false;
    populateEmployeeDetails();
};
</script>
<template>
    <div class="grid grid-cols-12 gap-2 mt-5">
        <!-- Profile -->
        <EmployeePersonalInformationSkeleton v-if="loading"/>
        
        <div
            v-else
            class="col-span-12 lg:col-span-4 2xl:col-span-4 flex lg:block flex-col-reverse"
        >
            <div class="intro-y box mt-5 lg:mt-0">
                <div class="relative flex items-center p-5">
                    <div class="w-12 h-12 image-fit">
                        <Avatar
                            :name="employee.name"
                            :ring="'online'"
                            :size="'sm'"
                        />
                    </div>
                    <div class="ml-4 mr-auto w-4/5">
                        <div class="font-medium text-base break-all">
                            {{ employee.name }}
                        </div>
                        <div class="text-slate-500">
                            {{ employee.position }}
                        </div>
                    </div>
                    <Button
                        v-if="AuthStore.hasPermission('can update employees')"
                        class="mb-2 mr-1"
                        variant="soft-secondary"
                        @click="updateEmployee(employee)"
                    >
                        <Lucide
                            class="w-5 h-5"
                            icon="Pencil"
                        />
                    </Button>
                </div>

                <TabEmployeeDetails
                    :employee="employee"
                    :showIdCard="false"
                    :showEditStatus="AuthStore.hasPermission('can update employees')"
                    @refresh="populateEmployeeDetails()"
                />
            </div>
        </div>
        <!-- End Profile -->

        <div class="col-span-12 lg:col-span-4">
            <!-- Employee Shifts -->
            <EmployeeShifts
                v-if="AuthStore.hasPermission('can view employee shifts')"
                :loading="loading"
                :shifts="employee.shifts"
                @refresh="populateEmployeeDetails"
            />

            <!-- Employee Client Assignment -->
            <EmployeeClientAssignment
                v-if="AuthStore.hasPermission('can view employee client assignment')"
                :employee="employee"
                @refresh="populateEmployeeDetails"
            />

            <!-- Employee Files -->
            <EmployeeRecentFile
                v-if="AuthStore.hasPermission('can view employee attachments')"
                :loading="loading"
                :employee="employee"
                @status="formEmployeeStatus"
            />

            <!-- Employee Notes -->
            <EmployeeNote
                v-if="AuthStore.hasPermission('can view employee note')"
            />
        </div>

        <div class="col-span-12 lg:col-span-4">
            <!-- Salary History -->
            <EmployeeSalaryHistory
                v-if="AuthStore.hasPermission('can view employee salary history')"
                :employee="employee"
            />
            <!-- Cost History -->
            <EmployeeCostHistory
                v-if="AuthStore.hasPermission('can view employee cost history')"
                :employee="employee"
                @close="populateEmployeeDetails"
            />
            <!-- Nonrefundable -->
            <EmployeeNonrefundableHistory
                v-if="AuthStore.hasPermission('can view employee nonrefundable history')"
                :employee="employee"
                @close="populateEmployeeDetails"
            />
            <!-- RE Fees -->
            <EmployeeReFees
                v-if="AuthStore.hasPermission('can view employee re fee')"
                :employee="employee"
                @close="populateEmployeeDetails"
            />
            <EmployeeStatusHistory
                v-if="
                    AuthStore.role.includes('admin')
                    || AuthStore.role.includes('finance')
                    || AuthStore.role.includes('payroll')
                    || AuthStore.role.includes('hr')
                "
                :employee="employee"
            />

            <!-- <div class="grid grid-cols-12 gap-6 blur-sm"> -->
            <!--                <PanelRecentActivities></PanelRecentActivities>-->
            <!-- <div class="intro-y box col-span-12 2xl:col-span-6"> -->
            <!--    <PanelNotes></PanelNotes> -->
            <!-- </div> -->
            <!-- BEGIN: Team Members -->

            <!-- END: Team Members -->
            <!-- BEGIN: Projects -->
            <!-- END: Projects -->
            <!-- BEGIN: Today Schedules -->
            <!--                <div class="intro-y box col-span-12 2xl:col-span-6">-->
            <!--                    <div-->
            <!--                        class="flex items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400"-->
            <!--                    >-->
            <!--                        <h2 class="font-medium text-base mr-auto">-->
            <!--                            Policy Compliance-->
            <!--                        </h2>-->
            <!--                        <button-->
            <!--                            class="tiny-slider-navigator btn btn-outline-secondary px-2 mr-2"-->
            <!--                            @click="prevTodaySchedules"-->
            <!--                        >-->
            <!--                            &lt;!&ndash;                            <ChevronLeftIcon class="w-4 h-4"/>&ndash;&gt;-->
            <!--                        </button>-->
            <!--                        <button-->
            <!--                            class="tiny-slider-navigator btn btn-outline-secondary px-2"-->
            <!--                            @click="nextTodaySchedules"-->
            <!--                        >-->
            <!--                            &lt;!&ndash;                            <ChevronRightIcon class="w-4 h-4"/>&ndash;&gt;-->
            <!--                        </button>-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash;                    <TinySlider ref-key="todaySchedulesRef" class="py-5">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <div class="px-5 text-center sm:text-left">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-medium text-lg"> Non Disclosure Agreement</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="text-slate-600 dark:text-slate-500 mt-2"> Client Policy</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="mt-2">January 1, 2022</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="flex flex-col sm:flex-row items-center mt-5">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <button&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    class="btn btn-secondary py-1 px-2 sm:ml-auto mt-3 sm:mt-0sm:ml-auto mt-3 sm:mt-0">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    View&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    Policy&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </button>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                    </TinySlider>&ndash;&gt;-->
            <!--                </div>-->
            <!--                <div class="intro-y box col-span-12 2xl:col-span-6">-->
            <!--                    <div-->
            <!--                        class="flex items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400"-->
            <!--                    >-->
            <!--                        <h2 class="font-medium text-base mr-auto">-->
            <!--                            Calendar Schedule-->
            <!--                        </h2>-->
            <!--                        <button-->
            <!--                            class="tiny-slider-navigator btn btn-outline-secondary px-2 mr-2"-->
            <!--                            @click="prevTodaySchedules"-->
            <!--                        >-->
            <!--                            &lt;!&ndash;                            <ChevronLeftIcon class="w-4 h-4"/>&ndash;&gt;-->
            <!--                        </button>-->
            <!--                        <button-->
            <!--                            class="tiny-slider-navigator btn btn-outline-secondary px-2"-->
            <!--                            @click="nextTodaySchedules"-->
            <!--                        >-->
            <!--                            &lt;!&ndash;                            <ChevronRightIcon class="w-4 h-4"/>&ndash;&gt;-->
            <!--                        </button>-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash;                    <TinySlider ref-key="todaySchedulesRef" class="py-5">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <div class="px-5 text-center sm:text-left">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-medium text-lg"> Labor Day</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="text-slate-600 dark:text-slate-500 mt-2"> Regular US Holiday</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="mt-2">11:15AM - 12:30PM</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="flex flex-col sm:flex-row items-center mt-5">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <div class="flex items-center text-slate-500">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    <MapPinIcon class="hidden sm:block w-4 h-4 mr-2"/>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    1396 Pooh Bear Lane, New Market&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <button&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    class="btn btn-secondary py-1 px-2 sm:ml-auto mt-3 sm:mt-0sm:ml-auto mt-3 sm:mt-0">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    View&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    On Calendar&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </button>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <div class="px-5 text-center sm:text-left">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-medium text-lg"> Independence Day</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="text-slate-600 dark:text-slate-500 mt-2"> Regular US Holiday</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="mt-2">11:15AM - 12:30PM</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="flex flex-col sm:flex-row items-center mt-5">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <div class="flex items-center text-slate-500">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    <MapPinIcon class="hidden sm:block w-4 h-4 mr-2"/>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    1396 Pooh Bear Lane, New Market&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <button&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    class="btn btn-secondary py-1 px-2 sm:ml-auto mt-3 sm:mt-0sm:ml-auto mt-3 sm:mt-0">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    View&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    On Calendar&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </button>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <div class="px-5 text-center sm:text-left">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-medium text-lg"> Independence Day</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="text-slate-600 dark:text-slate-500 mt-2"> Regular US Holiday</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="mt-2">11:15AM - 12:30PM</div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="flex flex-col sm:flex-row items-center mt-5">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <div class="flex items-center text-slate-500">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    <MapPinIcon class="hidden sm:block w-4 h-4 mr-2"/>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    1396 Pooh Bear Lane, New Market&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                <button&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    class="btn btn-secondary py-1 px-2 sm:ml-auto mt-3 sm:mt-0sm:ml-auto mt-3 sm:mt-0">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    View&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    On Calendar&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                </button>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                    </TinySlider>&ndash;&gt;-->
            <!--                </div>-->
            <!-- END: Today Schedules -->
            <!-- BEGIN: Work In Progress -->
            <!--                <Tab.Group class="intro-y box col-span-12 2xl:col-span-6">-->
            <!--                    <div-->
            <!--                        class="flex items-center px-5 py-5 sm:py-0 border-b border-slate-200/60 dark:border-darkmode-400">-->
            <!--                        <h2 class="font-medium text-base mr-auto">Steps</h2>-->
            <!--&lt;!&ndash;                        <Dropdown class="ml-auto sm:hidden">&ndash;&gt;-->
            <!--&lt;!&ndash;                            <DropdownToggle tag="a" class="w-5 h-5 block" href="javascript:;">&ndash;&gt;-->
            <!--&lt;!&ndash;                                <MoreHorizontalIcon class="w-5 h-5 text-slate-500"/>&ndash;&gt;-->
            <!--&lt;!&ndash;                            </DropdownToggle>&ndash;&gt;-->
            <!--&lt;!&ndash;                            <DropdownMenu class="w-40">&ndash;&gt;-->
            <!--&lt;!&ndash;                                <DropdownContent tag="div">&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <Tab.List class="block">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <Tab :fullWidth="false" class="dropdown-item cursor-pointer">New</Tab>&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <Tab :fullWidth="false" class="dropdown-item cursor-pointer">Last Week</Tab>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </Tab.List>&ndash;&gt;-->
            <!--&lt;!&ndash;                                </DropdownContent>&ndash;&gt;-->
            <!--&lt;!&ndash;                            </DropdownMenu>&ndash;&gt;-->
            <!--&lt;!&ndash;                        </Dropdown>&ndash;&gt;-->
            <!--                        <Tab.List class="nav-link-tabs w-auto ml-auto hidden sm:flex">-->
            <!--                            <Tab :fullWidth="false" class="py-5 cursor-pointer">New</Tab>-->
            <!--                            <Tab :fullWidth="false" class="py-5 cursor-pointer">Last Week</Tab>-->
            <!--                        </Tab.List>-->
            <!--                    </div>-->
            <!--                    <div class="p-5">-->
            <!--&lt;!&ndash;                        <TabPanels>&ndash;&gt;-->
            <!--&lt;!&ndash;                            <TabPanel>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="flex">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="mr-auto">Policy compliance</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div>20%</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="progress h-1 mt-2">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="progress-bar w-1/2 bg-primary" role="progressbar" aria-valuenow="0"&ndash;&gt;-->
            <!--&lt;!&ndash;                                             aria-valuemin="0" aria-valuemax="100"></div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <div class="mt-5">&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="flex">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="mr-auto">Onboarding</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div>2 / 20</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="progress h-1 mt-2">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="progress-bar w-1/4 bg-primary" role="progressbar" aria-valuenow="0"&ndash;&gt;-->
            <!--&lt;!&ndash;                                             aria-valuemin="0" aria-valuemax="100"></div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <div class="mt-5">&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="flex">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="mr-auto">Onboarding IT</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div>42</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="progress h-1 mt-2">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="progress-bar w-3/4 bg-primary" role="progressbar" aria-valuenow="0"&ndash;&gt;-->
            <!--&lt;!&ndash;                                             aria-valuemin="0" aria-valuemax="100"></div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <div class="mt-5">&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="flex">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="mr-auto">Orientation</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div>70%</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    <div class="progress h-1 mt-2">&ndash;&gt;-->
            <!--&lt;!&ndash;                                        <div class="progress-bar w-4/5 bg-primary" role="progressbar" aria-valuenow="0"&ndash;&gt;-->
            <!--&lt;!&ndash;                                             aria-valuemin="0" aria-valuemax="100"></div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <a href="" class="btn btn-secondary block w-40 mx-auto mt-5">View More Details</a>&ndash;&gt;-->
            <!--&lt;!&ndash;                            </TabPanel>&ndash;&gt;-->
            <!--&lt;!&ndash;                        </TabPanels>&ndash;&gt;-->
            <!--                    </div>-->
            <!--                </Tab.Group>-->
            <!-- END: Work In Progress -->
            <!-- BEGIN: Latest Tasks -->
            <!-- END: Latest Tasks -->
            <!-- </div> -->
        </div>
    </div>

    <Tab.Group class="bg-white rounded mt-8">
        <Tab.List
            class="flex-col justify-center text-center sm:flex-row lg:justify-start"
            variant="link-tabs"
        >
            <Tab :fullWidth="false">
                <Tab.Button class="py-4 cursor-pointer">Tickets</Tab.Button>
            </Tab>
            <Tab :fullWidth="false">
                <Tab.Button class="py-4 cursor-pointer">
                    Attendances
                </Tab.Button>
            </Tab>
            <Tab :fullWidth="false">
                <Tab.Button class="py-4 cursor-pointer">Schedules</Tab.Button>
            </Tab>
        </Tab.List>
        <Tab.Panels class="mt-5 intro-y">
            <Tab.Panel class="bg-gray-50 px-4 py-2">
                <EmployeeTickets />
            </Tab.Panel>

            <Tab.Panel class="bg-gray-50 px-4 py-2">
                <EmployeeAttendance />
            </Tab.Panel>

            <Tab.Panel class="bg-gray-50 px-4 py-2">
                <EmployeeSchedule />
            </Tab.Panel>
        </Tab.Panels>
    </Tab.Group>

    <FormSlider
        :header="'Employee Form Details'"
        :isOpen="isEmployeeFormSliderOut"
        @close="closeSlider()"
    >
        <FormEmployee
            :id="employeeId"
            :data="employeeApiData.data"
            @close="closeSlider()"
            @status="formEmployeeStatus($event)"
        >
        </FormEmployee>
    </FormSlider>
</template>
